<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button
                variant="primary"
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
        :filter="searchQuery"
      >
        <template #cell(project)="data">
          {{ getProjectsDisplayNames(data.item.project) }}
        </template>
        <template #cell(calling_mode)="data">
          {{
            data.item.calling_mode == "manuel"
              ? lang("t_queueManual")
              : data.item.calling_mode == "dialer"
              ? lang("t_queueDialer")
              : "IVN"
          }}
        </template>
        <template #cell(profiles)="data">
          {{ getProfileDisplayNames(data.item.profiles) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ records.length }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="project"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_project')"
                  label-for="project"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.project"
                    :options="projects"
                    :placeholder="lang('t_project')"
                    :reduce="(val) => val.internal_name"
                    label="display_name"
                    value="internal_name"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <!-- queue_name -->
              <validation-provider
                #default="validationContext"
                name="display_name"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueName')"
                  label-for="display_name"
                >
                  <b-form-input
                    id="display_name"
                    v-model="currentData.display_name"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <!-- profile -->
              <validation-provider
                #default="validationContext"
                name="profile"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_profile')"
                  label-for="profile"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.profiles"
                    :options="profiles"
                    :placeholder="lang('t_profile')"
                    :reduce="(val) => val.internal_name"
                    label="display_name"
                    multiple
                    value="internal_name"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <!-- search -->
              <validation-provider
                #default="validationContext"
                name="search"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueCallingMode')"
                  label-for="search"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.calling_mode"
                    :options="searchOptions"
                    :placeholder="lang('t_queueCallingMode')"
                    :reduce="(val) => val.key"
                    label="value"
                    value="key"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <!-- queue number -->
              <validation-provider
                #default="validationContext"
                name="queue_number"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueNumber')"
                  label-for="queue_number"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.number"
                    :options="extens"
                    :placeholder="lang('t_queueNumber')"
                    :reduce="(val) => val.value"
                    label="label"
                    value="value"
                    :selectable="(option) => !option.disabled"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <!-- queue number -->
              <validation-provider
                #default="validationContext"
                name="queue_strat"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueStrategy')"
                  label-for="queue_strat"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.strategy"
                    :options="strategyOptions"
                    :placeholder="lang('t_queueStrategy')"
                    :reduce="(val) => val.key"
                    label="value"
                    value="key"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <!-- queue number -->
              <validation-provider
                #default="validationContext"
                name="type"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_type')"
                  label-for="type"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    @input="typeChanged"
                    v-model="currentData.type"
                    :options="types"
                    :placeholder="lang('t_type')"
                    :reduce="(val) => val.key"
                    label="label"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <!-- queue interactionweight -->
              <validation-provider
                #default="validationContext"
                name="interactionweight"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_interactionWeight')"
                  label-for="interactionweight"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    :disabled="currentData.type != 'Text'"
                    v-model="currentData.interactionweight"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    min="0"
                    max="100"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <!-- agentTimeout -->
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="agentTimeout"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueAgentTimeout')"
                  label-for="agentTimeout"
                >
                  <b-form-input
                    v-model="currentData.timeout"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- queueWrapupTime -->
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="queueWrapupTime"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueWrapupTime')"
                  label-for="queueWrapupTime"
                >
                  <b-form-input
                    v-model="currentData.wrapuptime"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <!-- queueAnnouncePosition -->
              <validation-provider
                #default="validationContext"
                name="queueAnnouncePosition"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueAnnouncePosition')"
                  label-for="queueAnnouncePosition"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.announce_position"
                    :options="queueAnnouncePositionOptions"
                    label="display_name"
                    :reduce="(val) => val.internal_name"
                    :placeholder="lang('t_queueAnnouncePosition')"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" v-if="currentData.announce_position == 'yes'">
              <!-- queueAnnounceFrequency -->
              <validation-provider
                #default="validationContext"
                name="queueAnnounceFrequency"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueAnnounceFrequency')"
                  label-for="queueAnnounceFrequency"
                >
                  <b-form-input
                    v-model="currentData.announce_frequency"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <!-- queueAnnounceHoldTime -->
              <validation-provider
                #default="validationContext"
                name="queueAnnounceHoldTime"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueAnnounceHoldTime')"
                  label-for="queueAnnounceHoldTime"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.wait_time_announcement"
                    :options="queueAnnouncePositionOptions"
                    label="display_name"
                    :reduce="(val) => val.internal_name"
                    :placeholder="lang('t_queueAnnounceHoldTime')"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" v-if="currentData.wait_time_announcement == 'yes'">
              <!-- queueAnnounceFrequency -->
              <validation-provider
                #default="validationContext"
                name="queueAnnounceFrequency"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueAnnounceFrequency')"
                  label-for="queueAnnounceFrequency"
                >
                  <b-form-input
                    v-model="currentData.wait_time_frequency"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="queueAnnouncePosition"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueMusicOnHold')"
                  label-for="queueDestinationType"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    @input="
                      () => {
                        currentData.destination.target = '';
                      }
                    "
                    v-model="currentData.music_on_hold"
                    :options="announcements"
                    label="display_name"
                    :reduce="(val) => val.internal_name"
                    :placeholder="lang('t_queueMusicOnHold')"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <!-- queueRetry -->
              <validation-provider
                #default="validationContext"
                name="queueRetry"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueTimeOut')"
                  label-for="queueRetry"
                >
                  <b-form-input
                    @input="
                      () => {
                        currentData.destination.type = '';
                      }
                    "
                    v-model="currentData.queue_timeout"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" v-if="currentData.queue_timeout > 0">
              <validation-provider
                #default="validationContext"
                name="queueAnnouncePosition"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_detinationType')"
                  label-for="queueDestinationType"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    @input="
                      () => {
                        currentData.destination.target = '';
                      }
                    "
                    v-model="currentData.destination.type"
                    :options="destination_types"
                    label="display_name"
                    :reduce="(val) => val.internal_name"
                    :placeholder="lang('t_detinationType')"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="6"
              v-if="
                currentData.destination.type != '' &&
                currentData.destination.type == 'extension'
              "
            >
              <validation-provider
                #default="validationContext"
                name="queueDestinationTarget"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_detinationTarget')"
                  label-for="queueDestinationTarget"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.destination.target"
                    :options="users"
                    label="username"
                    :reduce="(val) => val._id"
                    :placeholder="lang('t_detinationTarget')"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="6"
              v-if="
                currentData.destination.type != '' &&
                currentData.destination.type == 'voice_mail'
              "
            >
              <validation-provider
                #default="validationContext"
                name="queueDestinationTarget"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_detinationTarget')"
                  label-for="queueDestinationTarget"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.destination.target"
                    :options="users.filter((e) => e.email != '')"
                    label="username"
                    :reduce="(val) => val._id"
                    :placeholder="lang('t_detinationTarget')"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="6"
              v-if="
                currentData.destination.type != '' &&
                currentData.destination.type == 'ivr'
              "
            >
              <validation-provider
                #default="validationContext"
                name="queueDestinationTarget"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_detinationTarget')"
                  label-for="queueDestinationTarget"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.destination.target"
                    :options="ivr"
                    label="display_name"
                    :reduce="(val) => val.internal_name"
                    :placeholder="lang('t_detinationTarget')"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="6"
              v-if="
                currentData.destination.type != '' &&
                currentData.destination.type == 'queue'
              "
            >
              <validation-provider
                #default="validationContext"
                name="queueDestinationTarget"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_detinationTarget')"
                  label-for="queueDestinationTarget"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.destination.target"
                    :options="records"
                    label="display_name"
                    :reduce="(val) => val.internal_name"
                    :placeholder="lang('t_detinationTarget')"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="queueAnnouncePosition"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_queueServiceLevel')"
                  label-for="queueServiceLevel"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    v-model="currentData.service_level"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="queueAnnouncePosition"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_autoAnswer')"
                  label-for="t_autoAnswer"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-checkbox
                    v-model="currentData.auto_answer"
                    class="custom-control-primary mt-1"
                  >
                    <span class="vs-checkbox">
                      <span class="vs-checkbox--check">
                        <i class="vs-icon feather icon-check" />
                        {{ lang("t_autoAnswer") }}
                      </span>
                    </span>
                  </b-form-checkbox>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="pinedAgent"
              >
                <b-form-group
                  :label="lang('t_agent')"
                  label-for="static_users"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    :deselectFromDropdown="true"
                    :closeOnSelect="false"
                    v-model="currentData.static_users"
                    :options="users"
                    label="username"
                    :reduce="(val) => val.identifier"
                    :placeholder="lang('t_agent')"
                    multiple
                  >
                    <template #list-header>
                      <div class="w-100 mb-50">
                        <b-button
                          class="w-50"
                          size="sm"
                          variant="outline-primary"
                          @click="
                            () => {
                              currentData.static_users = users.map(
                                (e) => e.identifier
                              );
                            }
                          "
                        >
                          <feather-icon size="14" icon="CheckCircleIcon" />
                          {{ lang("t_selectAll") }}
                        </b-button>
                        <b-button
                          class="w-50"
                          size="sm"
                          variant="outline-danger"
                          @click="
                            () => {
                              currentData.static_users = [];
                            }
                          "
                        >
                          <feather-icon size="14" icon="XOctagonIcon" />
                          {{ lang("t_cleanSelections") }}
                        </b-button>
                      </div>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="pinedAgent"
              >
                <b-form-group
                  :label="lang('t_finishCodes')"
                  label-for="finish_codes"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    :deselectFromDropdown="true"
                    :closeOnSelect="false"
                    v-model="currentData.finish_codes"
                    :options="finish_codes"
                    label="finish_code"
                    :reduce="(val) => val.finish_code"
                    :placeholder="lang('t_finishCodes')"
                    multiple
                  >
                    <template #list-header>
                      <div class="w-100 mb-50">
                        <b-button
                          class="w-50"
                          size="sm"
                          variant="outline-primary"
                          @click="
                            () => {
                              currentData.finish_codes = finish_codes.map(
                                (e) => e.finish_code
                              );
                            }
                          "
                        >
                          <feather-icon size="14" icon="CheckCircleIcon" />
                          {{ lang("t_selectAll") }}
                        </b-button>
                        <b-button
                          class="w-50"
                          size="sm"
                          variant="outline-danger"
                          @click="
                            () => {
                              currentData.finish_codes = [];
                            }
                          "
                        >
                          <feather-icon size="14" icon="XOctagonIcon" />
                          {{ lang("t_cleanSelections") }}
                        </b-button>
                      </div>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // UsersListFilters,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      currentData: {
        project: "",
        internal_name: "",
        display_name: "",
        profiles: [],
        temp_profiles: [],
        added_profiles: [],
        removed_profiles: [],
        calling_mode: "manuel",
        number: "",
        strategy: "rrmemory",
        timeout: 15,
        wrapuptime: 15,
        wait_time_announcement: "no",
        wait_time_frequency: 0,
        announce_position: "no",
        announce_frequency: 0,
        weight: 0,
        queue_timeout: 0,
        music_on_hold: "default",
        destination: {
          type: "",
          target: "",
        },
        static_users: [],
        finish_codes: [],
        service_level: 30,
        auto_answer: false,
        type: 'Voice',
        interactionweight: 100
      },
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      ivr: [],
      users: [],
      records: [],
      extens: [],
      queues: [],
      projects: [],
      profiles: [],
      finish_codes: [],
      announcements: [],
      types: [
        {
          key: "Voice",
          label: globalThis._lang("t_qVoice"),
          sortable: true,
        },
        {
          key: "Video",
          label: globalThis._lang("t_qVideo"),
          sortable: true,
        },
        {
          key: "Text",
          label: globalThis._lang("t_qText"),
          sortable: true,
        }
      ],
      tableColumns: [
        {
          key: "project",
          label: globalThis._lang("t_project"),
          sortable: true,
        },
        {
          key: "display_name",
          label: globalThis._lang("t_queueName"),
          sortable: true,
        },
        {
          key: "profiles",
          label: globalThis._lang("t_profile"),
          sortable: true,
        },
        {
          key: "number",
          label: globalThis._lang("t_queueNumber"),
          sortable: true,
        },
        {
          key: "strategy",
          label: globalThis._lang("t_queueStrategy"),
          sortable: true,
        },
        {
          key: "calling_mode",
          label: globalThis._lang("t_queueCallingMode"),
          sortable: true,
        },
        {
          key: "actions",
          label: globalThis._lang("t_actions"),
          sortable: false,
        },
      ],
      destination_types: [
        { internal_name: "ivr", display_name: "IVR" },
        { internal_name: "queue", display_name: "Kuyruk" },
        // { internal_name: 'extension', display_name: 'Dahili' },
        { internal_name: "voice_mail", display_name: "Sesli Mesaj" },
      ],
      queueAnnouncePositionOptions: [
        { internal_name: "yes", display_name: globalThis._lang("t_yes") },
        { internal_name: "no", display_name: globalThis._lang("t_no") },
      ],
    };
  },
  mounted: async function () {
    this.is_progress = true;
    await this.get_profiles();
    await this.get_exten();
    await this.GetProjects();
    await this.GetAnnouncements();
    await this.GetUsers();
    await this.GetIvr();
    await this.get_records();
    await this.GetFinishCodes();
    this.is_progress = false;
  },
  methods: {
    GetFinishCodes: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/FinishCodes`)
      ).data;
      this.finish_codes = response.finish_codes.filter(e => !e.is_product_finish_code && !e.is_system && !e.is_chat_finish_code);
      // let fc = globalThis.permissions["finish_code"]
      // // console.log("this.$store.state.privileges", this.$store.state.privileges);
      // for (const row of response.finish_codes.filter(e => !e.is_product_finish_code && !e.is_system && !e.is_chat_finish_code)) {
      //   // console.log(row);
      //   if (fc.includes(row['finish_code'])) {
      //     this.finish_codes.push(row);
      //   }
      // }
    },
    typeChanged() {
      this.currentData.interactionweight = 100;
    },
    getProfileDisplayNames(pf) {
      let _profiles = pf;
      let text = "";
      for (const item of _profiles) {
        if (item != "voyce_profile_base") {
          text +=
            (this.profiles.find((e) => e.internal_name == item) != undefined
              ? this.profiles.find((e) => e.internal_name == item).display_name
              : item) + ",";
        }
      }
      text = text.substr(0, text.length - 1);
      return text;
    },
    getProjectsDisplayNames(pf) {
      return this.projects.find((e) => e.internal_name == pf).display_name;
    },
    get_exten: async function () {
      this.extens = [];
      for (let index = 5000; index < 8000; index++) {
        this.extens.push({
          label: index,
          value: index,
          disabled: false,
        });
      }
    },
    GetAnnouncements: async function () {
      var response = (await this.$http_in.get(`optmgr/v1/MusicOnHold`)).data;

      this.announcements.push({
        internal_name: "quiet",
        display_name: globalThis._lang("t_none"),
      });
      this.announcements.push({
        internal_name: "default",
        display_name: "Standart",
      });
      this.announcements = [...this.announcements, ...response];
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`optmgr/v1/Project`)).data;

      this.projects = response;
    },
    GetUsers: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/User`)).data;

      this.users = response;
    },
    GetIvr: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/Ivr`)).data;

      this.ivr = response;
    },
    get_profiles: async function () {
      var response = (await this.$http_in.get(`Auth/v1/Profiles`)).data;
      this.profiles = response;
    },

    setQueueMusic() {
      if (this.currentData.calling_mode == "dialer") {
        this.currentData.musiconhold = false;
      }
    },
    checkIsItemDisabled(item) {
      if (item.disabled) {
        return true;
      }
      return false;
    },
    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/optmgr/v1/Queue`)).data;
      console.log("resp", response);
      this.records = response;

      for (const item of response) {
        this.extens.find((e) => e.value == item.number).disabled = true;
      }
      this.is_progress = false;
    },
    check_data() {
      if (
        this.currentData.display_name == "" ||
        this.currentData.project == "" ||
        this.currentData.number == ""
      ) {
        return true;
      }
      return false;
    },
    add_records: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.is_progress = true;
      this.currentData.added_profiles = this.currentData.profiles.filter(
        (p) => !this.currentData.temp_profiles.includes(p)
      );
      this.currentData.removed_profiles = this.currentData.temp_profiles.filter(
        (p) => !this.currentData.profiles.includes(p)
      );
      this.currentData.strategy = "rrmemory";
      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`/optmgr/v1/Queue`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`/optmgr/v1/Queue`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;
      }
      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.delete(
          `/optmgr/v1/Queue/${this.currentData.internal_name}`
        )
      ).data;
      this.is_progress = false;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        project: "",
        internal_name: "",
        display_name: "",
        profiles: [],
        temp_profiles: [],
        added_profiles: [],
        removed_profiles: [],
        calling_mode: "manuel",
        number: "",
        strategy: "rrmemory",
        timeout: 15,
        wrapuptime: 15,
        wait_time_announcement: "no",
        wait_time_frequency: 0,
        announce_position: "no",
        announce_frequency: 0,
        weight: 0,
        queue_timeout: 0,
        music_on_hold: "default",
        destination: {
          type: "",
          target: "",
        },
        static_users: [],
        finish_codes: [],
        service_level: 30,
        auto_answer: false,
        type: 'Voice',
        interactionweight: 100
      };
    },

    updateSelected(item) {
      this.currentData = {
        ...item,
        profiles: item.profiles,
        temp_profiles: item.profiles,
        musiconhold: item.musiconhold == "quiet" ? false : true,
      };
      console.log("item", item);
      this.isEdit = true;
      //this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("res", result);
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);
    const isAddNewUserSidebarActive = ref(false);

    const searchOptions = [
      { key: "manuel", value: `${globalThis._lang("t_queueManual")}` },
      { key: "dialer", value: `${globalThis._lang("t_queueDialer")}` },
      { key: "ivn", value: `IVN` },
    ];
    const strategyOptions = [
      { key: "rrmemory", value: globalThis._lang("t_queueRrmemory") },
      { key: "ringall", value: globalThis._lang("t_queueRingall") },
      { key: "leastrecent", value: globalThis._lang("t_queueLeastrecent") },
      { key: "fewestcalls", value: globalThis._lang("t_queueFewestcalls") },
      { key: "random", value: globalThis._lang("t_queueRandom") },
    ];
    const queueOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,
      queueOptions,
      searchOptions,
      projectOptions,
      profileOptions,
      strategyOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
