<template>
  <div>
    <div class="mb-1" style="overflow-x: auto; white-space: nowrap">
      <b-button
        class="ml-50"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selectedButton != 'categories' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'categories'"
      >
        {{ lang("t_categories") }}
      </b-button>
      <b-button
        class="ml-50"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selectedButton != 'statuses' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'statuses'"
      >
        {{ lang("t_statuses") }}
      </b-button>
    </div>
    <b-card>
      <categories v-if="selectedButton == 'categories'"></categories>
      <statuses v-if="selectedButton == 'statuses'"></statuses>
    </b-card>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton } from 'bootstrap-vue'
// import SmsSender from './SmsSender.vue'

import Categories from './Categories/list/List.vue'
import Statuses from './Statuses/list/List.vue'

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: 'categories',
      // optionsButton: [
      //   { text: 'Anonslar', value: 'announcements' },
      //   { text: 'Kuyruklar', value: 'inbound_routes' },
      //   { text: 'Projeler', value: 'ivr' },
      //   { text: 'Projeler', value: 'lines' },
      //   { text: 'Projeler', value: 'time-conditions' },
      //   // { text: 'Ayarlar', value: 'settings' },
      // ],
    }
  },
  components: {
    Categories,
    Statuses,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton
  },
}
</script>

<style>
</style>
