<template>
  <b-container fluid class="px-0">
    <b-row class="mb-1 px-0 mx-0">
      <b-button
        v-if="menu.includes('users')"
        style="width: 130px"
        :variant="
          selectedButton != 'users' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'users'"
      >
        {{ lang("t_users") }}
      </b-button>
      <b-button
        v-if="menu.includes('routers')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'routers' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'routers'"
      >
        {{ lang("t_extensionRoutes") }}
      </b-button>
      <b-button
        v-if="menu.includes('queues')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'queues' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'queues'"
      >
        {{ lang("t_queues") }}
      </b-button>
      <b-button
        v-if="menu.includes('queue_group')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'queue_group' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'queue_group'"
      >
        {{ lang("t_queueGroup") }}
      </b-button>
      <b-button
        v-if="menu.includes('projects')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'projects' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'projects'"
      >
        {{ lang("t_projects") }}
      </b-button>
      <b-button
        v-if="menu.includes('statuses')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'statuses' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'statuses'"
      >
        {{ lang("t_statuses") }}
      </b-button>
      <b-button
        v-if="menu.includes('finish_codes')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'finish_codes'
            ? 'outline-primary'
            : 'relief-primary'
        "
        pill
        @click="selectedButton = 'finish_codes'"
      >
        {{ lang("t_finishCodes") }}
      </b-button>
      <b-button
        v-if="menu.includes('black_list')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'black_list' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'black_list'"
      >
        {{ lang("t_blackList") }}
      </b-button>
      <b-button
        v-if="menu.includes('profiles')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'profiles' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'profiles'"
      >
        {{ lang("t_profiles") }}
      </b-button>
      <!-- <b-button
          class="float-right ml-auto mr-1"
          :variant="
            selectedButton != 'settings'
              ? 'outline-primary'
              : 'relief-primary'
          "
          pill
          @click="selectedButton = 'settings'"
        >
          Ayarlar
        </b-button> -->
    </b-row>
    <b-card>
      <users v-if="selectedButton == 'users'"></users>
      <queues v-if="selectedButton == 'queues'"></queues>
      <queue-group v-if="selectedButton == 'queue_group'"></queue-group>
      <projects v-if="selectedButton == 'projects'"></projects>
      <black-list v-if="selectedButton == 'black_list'"></black-list>
      <finish-codes v-if="selectedButton == 'finish_codes'"></finish-codes>
      <statuses v-if="selectedButton == 'statuses'"></statuses>
      <profiles v-if="selectedButton == 'profiles'"></profiles>
      <routers v-if="selectedButton == 'routers'"></routers>
      <!-- <sms-settings v-if="selectedButton == 'settings'"></sms-settings> -->
    </b-card>
  </b-container>
</template>

<script>
import { BContainer, BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton } from 'bootstrap-vue'
// import SmsSender from './SmsSender.vue'
import Users from './Users/list/List.vue'
import Queues from './Queues/list/List.vue'
import Projects from './Projects/list/List.vue'
import Routers from './Routers/list/List.vue'
import BlackList from './BlackList/list/List.vue'
import FinishCodes from './FinishCodes/Main.vue'
import Statuses from './Statuses/Main.vue'
import Profiles from './Profiles/Main.vue'
import QueueGroup from './QueueGroup/QueueGroup.vue'
export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: '',
      optionsButton: [
        { text: globalThis._lang("t_users"), value: 'users' },
        { text: globalThis._lang("t_queues"), value: 'queues' },
        { text: globalThis._lang("t_projects"), value: 'projects' },
        // { text: 'Ayarlar', value: 'settings' },
      ],
      menu: [],
    }
  },
  components: {
    BContainer,
    Profiles,
    Statuses,
    FinishCodes,
    BlackList,
    QueueGroup,
    Routers,
    Users,
    Queues,
    Projects,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton
  },
  mounted: async function () {
    this.menu = globalThis.permissions["menu"];
    if (this.menu.includes('users')) {
      this.selectedButton = 'users';
    }
  }
}
</script>

<style>
</style>
