<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <div class="mb-1" style="overflow-x: auto; white-space: nowrap">
      <b-button
        class="ml-50"
        :variant="
          selectedButton != 'affected_objects'
            ? 'outline-primary'
            : 'relief-primary'
        "
        pill
        @click="selectedButton = 'affected_objects'"
      >
        {{ lang("t_affectedObjects") }}
      </b-button>
      <b-button
        class="ml-50"
        :variant="
          selectedButton != 'permissions' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'permissions'"
      >
        {{ lang("t_permissions") }}
      </b-button>
    </div>
    <b-card class="mb-0">
      <b-form-group>
        <v-select
          @input="updateSelected(profile)"
          v-model="profile"
          :options="profiles"
          :placeholder="lang('t_profileName')"
          :reduce="(option) => option"
          label="display_name"
        >
          <template v-slot:option="option">
            <!-- <span :class="'UserIcon'"></span> -->
            <span v-if="option.internal_name == 'new_record'">
              <feather-icon icon="PlusIcon" />
              {{ lang("t_addNewRecord") }}
            </span>
            <span v-else>{{ option.display_name }}</span>
          </template>
        </v-select>
        <b-form-input
          v-model="profile.display_name"
          :placeholder="lang('t_displayName')"
          id="profile"
          trim
          class="mt-50"
        />
      </b-form-group>

      <b-button
        @click="SetProfile"
        class="float-right ml-1"
        variant="primary"
        >{{ lang("t_save") }}</b-button
      >

      <b-button
        @click="DeleteProfilePopup"
        v-if="profile.internal_name != 'new_record'"
        class="float-right"
        variant="danger"
        >{{ lang("t_delete") }}</b-button
      >
    </b-card>
    <b-card v-if="selectedButton == 'affected_objects'">
      <b-row>
        <b-col>
          <b-card
            border-variant="primary"
            style="height: 34vh; max-height: 34vh"
          >
            <b-row align-h="between">
              <span class="ml-50">{{ lang("t_users") }}</span>
              <div
                @click="
                  () => {
                    modal_object_list = true;
                    type_selection = 1;
                  }
                "
              >
                <b-avatar
                  size="sm"
                  :badge="
                    object_list.temp_users.filter((e) => e.checked == true)
                      .length || 0
                  "
                  class="mr-1 cursor-pointer"
                  variant="primary"
                  badge-variant="success"
                >
                  <feather-icon size="14" icon="PlusIcon" />
                </b-avatar>
              </div>
            </b-row>
            <div
              style="
                height: 25vh;
                max-height: 25vh !important;
                min-height: 25vh;
                overflow-y: auto;
              "
            >
              <div
                class="border-primary rounded mt-1 px-1"
                v-for="(item, i) in profile_objects.users"
                :key="i"
              >
                <b-row class="mx-25" align-h="between" align-v="center">
                  <span class="py-auto">
                    {{ item.object_name }}
                  </span>

                  <b-button
                    @click="deleteObject(item.object_name)"
                    variant="flat-danger"
                    class="btn-icon"
                  >
                    <feather-icon size="14" icon="Trash2Icon" />
                  </b-button>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            border-variant="primary"
            style="height: 34vh; max-height: 34vh"
          >
            <b-row align-h="between">
              <span class="ml-50">{{ lang("t_queues") }}</span>
              <div
                @click="
                  () => {
                    modal_object_list = true;
                    type_selection = 2;
                  }
                "
              >
                <b-avatar
                  size="sm"
                  :badge="
                    object_list.temp_queues.filter((e) => e.checked == true)
                      .length || 0
                  "
                  class="mr-1 cursor-pointer"
                  variant="primary"
                  badge-variant="success"
                >
                  <feather-icon size="14" icon="PlusIcon" />
                </b-avatar>
              </div>
            </b-row>
            <div
              style="
                height: 25vh;
                max-height: 25vh !important;
                min-height: 25vh;
                overflow-y: auto;
              "
            >
              <div
                class="border-primary rounded mt-1 px-1"
                v-for="(item, i) in profile_objects.queues"
                :key="i"
              >
                <b-row class="mx-25" align-h="between" align-v="center">
                  <span class="py-auto">
                    {{ queue_names.get(item.object_name) }}
                  </span>

                  <b-button
                    @click="deleteObject(item.object_name)"
                    variant="flat-danger"
                    class="btn-icon"
                  >
                    <feather-icon size="14" icon="Trash2Icon" />
                  </b-button>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            border-variant="primary"
            style="height: 34vh; max-height: 34vh"
          >
            <b-row align-h="between">
              <span class="ml-50">{{ lang("t_projects") }}</span>
              <div
                @click="
                  () => {
                    modal_object_list = true;
                    type_selection = 3;
                  }
                "
              >
                <b-avatar
                  size="sm"
                  :badge="
                    object_list.temp_projects.filter((e) => e.checked == true)
                      .length || 0
                  "
                  class="mr-1 cursor-pointer"
                  variant="primary"
                  badge-variant="success"
                >
                  <feather-icon size="14" icon="PlusIcon" />
                </b-avatar>
              </div>
            </b-row>
            <div
              style="
                height: 25vh;
                max-height: 25vh !important;
                min-height: 25vh;
                overflow-y: auto;
              "
            >
              <div
                class="border-primary rounded mt-1 px-1"
                v-for="(item, i) in profile_objects.projects"
                :key="i"
              >
                <b-row class="mx-25" align-h="between" align-v="center">
                  <span class="py-auto">
                    {{ project_names.get(item.object_name) }}
                  </span>

                  <b-button
                    @click="deleteObject(item.object_name)"
                    variant="flat-danger"
                    class="btn-icon"
                  >
                    <feather-icon size="14" icon="Trash2Icon" />
                  </b-button>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-else>
      <b-row>
        <b-col
          cols="4"
          v-for="(item, i) in Object.keys(tmp_privileges).filter(
            (e) => e != 'custom_tab'
          )"
          :key="i"
        >
          <b-card
            style="min-height: 30vh; max-height: 30vh; overflow-y: auto"
            border-variant="primary"
          >
            <div class="mb-1">
              <b-row>
                <b-col cols="8">
                  <b-form-input
                    v-model="filterText[item]"
                    @input="filterPrivileges(item)"
                    size="sm"
                    :placeholder="lang(`t_${item}`)"
                    trim
                  />
                </b-col>
                <b-col cols="4">
                  <b-button
                    class="btn-icon float-right"
                    size="sm"
                    variant="outline-danger"
                    @click="checkAllPrivileges(false, item)"
                  >
                    <feather-icon icon="XOctagonIcon" />
                  </b-button>
                  <b-button
                    class="btn-icon float-right mr-1"
                    size="sm"
                    @click="checkAllPrivileges(true, item)"
                    variant="outline-primary"
                  >
                    <feather-icon icon="CheckCircleIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div style="min-height: 18vh; max-height: 18vh; overflow-y: auto">
              <div
                v-for="(item2, j) in tmp_privileges[item]"
                :key="j"
                class="d-flex align-items-center justify-content-between"
              >
                <b-form-checkbox
                  @change="add_to_selected(item, item2.internal_name)"
                  class="mt-25"
                  v-model="item2.checked"
                >
                  {{ item2.display_name }}
                </b-form-checkbox>
              </div>
            </div>
          </b-card>
        </b-col>
        <!-- <b-col cols="4">
          <b-card
            style="min-height: 25vh; max-height: 25vh; overflow-y: auto"
            border-variant="primary"
            :title="'Diğer'"
          >
            <div style="min-height: 15vh; max-height: 15vh; overflow-y: auto">
              <div
                v-for="(item2, j) in privileges[0].children.filter(
                  (e) => !e.children
                )"
                :key="j"
                class="d-flex align-items-center justify-content-between"
              >
                <b-form-checkbox class="mt-25" v-model="item2.checked">
                  {{ item2.name }}
                </b-form-checkbox>
              </div>
            </div>
          </b-card>
        </b-col> -->
      </b-row>
    </b-card>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="
        type_selection == 1
          ? lang('t_users')
          : type_selection == 2
          ? lang('t_queues')
          : lang('t_projects')
      "
      v-model="modal_object_list"
      centered
    >
      <div class="business-items mx-2">
        <b-form-input
          v-model="search_value"
          :placeholder="lang('t_search')"
          trim
        />
        <b-row class="mt-50">
          <b-col>
            <b-button block variant="outline-danger" @click="checkAll(false)">
              {{ lang("t_clearAll") }}
            </b-button>
          </b-col>
          <b-col>
            <b-button @click="checkAll(true)" block variant="outline-primary">
              {{ lang("t_selectAll") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-card
        v-if="type_selection == 1"
        class="business-card"
        style="min-height: 35vh; max-height: 35vh; overflow-y: auto"
      >
        <div class="business-items">
          <div
            v-for="(item, i) in filteredUsers"
            :key="i"
            class="business-item"
          >
            <div class="d-flex align-items-center justify-content-between">
              <b-form-checkbox v-model="item.checked">
                {{ item.username }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </b-card>
      <b-card
        v-if="type_selection == 2"
        class="business-card"
        style="min-height: 35vh; max-height: 35vh; overflow-y: auto"
      >
        <div class="business-items">
          <div
            v-for="(item, i) in filteredQueues"
            :key="i"
            class="business-item"
          >
            <div class="d-flex align-items-center justify-content-between">
              <b-form-checkbox v-model="item.checked">
                {{ item.display_name }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </b-card>
      <b-card
        v-if="type_selection == 3"
        class="business-card"
        style="min-height: 35vh; max-height: 35vh; overflow-y: auto"
      >
        <div class="business-items">
          <div
            v-for="(item, i) in filteredProjects"
            :key="i"
            class="business-item"
          >
            <div class="d-flex align-items-center justify-content-between">
              <b-form-checkbox v-model="item.checked">
                {{ item.display_name }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'

import { BAvatar, BFormCheckbox, BModal, BFormInput, BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BCardHeader, BLink, BButton } from 'bootstrap-vue'


export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      is_progress: false,
      selectedButton: 'affected_objects',
      modal: false,
      modal_object_list: false,
      modal_delete_object: false,
      type_selection: 1,
      is_alert: false,
      search_list: '',
      alert_message: '',
      alert_type: 'warning',
      edit: false,
      selected_profile: {},
      deleted_object: '',
      queue_names: new Map(),
      custom_tab_names: new Map(),
      project_names: new Map(),
      queues: [],
      profiles: [],
      search_value: '',
      filterText: {
        status: '',
        finish_code: '',
        custom_tab: '',
        queue: '',
        dial_plan: '',
        menu: '',
        chat: '',
      },

      selected_objects: {
        users: [],
        queues: [],
        projects: [],
      },
      object_list: {
        users: [],
        temp_users: [],
        queues: [],
        temp_queues: [],
        projects: [],
        temp_projects: [],
      },
      temp_object_list: {
        users: [],
        queues: [],
        projects: [],
      },
      profile_objects: {
        users: [],
        queues: [],
        projects: [],
      },
      temp_profile_objects: {
        users: [],
        queues: [],
        projects: [],
      },

      selected_privileges: {
        status: [],
        finish_code: [],
        custom_tab: [],
        queue: [],
        dial_plan: [],
        menu: [],
        chat: [],
      },
      tmp_privileges: {
        status: [],
        finish_code: [],
        custom_tab: [],
        queue: [],
        dial_plan: [],
        menu: [],
        chat: [],
        crm: [],
        other: [],
        report: [],
      },
      tmp_privileges2: {
        status: [],
        finish_code: [],
        custom_tab: [],
        queue: [],
        dial_plan: [],
        menu: [],
        chat: [],
        crm: [],
        other: [],
        report: [],
      },

      // privileges: [
      //   {
      //     id: 1,
      //     name: globalThis._lang("t_all"),
      //     locked: false,
      //     children: [
      //       {
      //         id: 2,
      //         name: globalThis._lang("t_statuses"),
      //         children: [
      //           {
      //             id: 3,
      //             name: 'Uygun',
      //             category: 'Status',
      //           },
      //           {
      //             id: 4,
      //             name: 'Mola',
      //           }, // ! Fill rest from DB
      //         ],
      //       },
      //       {
      //         id: 5,
      //         name: globalThis._lang("t_finishCodes"),
      //         children: [
      //           {
      //             id: 6,
      //             name: 'Başarılı',
      //           },
      //           {
      //             id: 7,
      //             name: 'Cevapsız',
      //           },
      //         ],
      //       },
      //       {
      //         id: 10,
      //         name: globalThis._lang("t_customTabs"),
      //         children: [
      //           // ! Fill from DB
      //         ],
      //       },

      //       {
      //         id: 11,
      //         name: globalThis._lang("t_queues"),
      //         children: [
      //           {
      //             id: 12,
      //             name: 'Attack',
      //             children: [
      //               { id: 13, name: 'Yazılı Sufle' },
      //               { id: 14, name: 'Sözlü Sufle' },
      //               { id: 15, name: 'Katıl' },
      //               { id: 16, name: 'Sonlandır' },
      //               { id: 17, name: 'Duyuru' },
      //               { id: 18, name: 'Dinle' },
      //               { id: 19, name: 'Aktar' },
      //               { id: 20, name: 'Çağrı Dinleme' },
      //               { id: 21, name: 'Çağrı İndirme' },
      //             ],
      //           },
      //           // ! Fill from DB, Queues and Users
      //         ],
      //       },
      //       {
      //         id: 23,
      //         name: globalThis._lang("t_dialPlan"),
      //         children: [
      //           // ! Fill from DB
      //         ],
      //       },
      //       {
      //         id: 24,
      //         name: globalThis._lang("t_menu"),
      //         children: [
      //           // ! Fill from DB
      //         ],
      //       },
      //       {
      //         id: 25,
      //         name: globalThis._lang("t_chat"),
      //         category: 'Chat',
      //       },
      //       {
      //         id: 26,
      //         name: globalThis._lang("t_announcement"),
      //         category: 'Announcement',
      //       },
      //       {
      //         id: 27,
      //         name: globalThis._lang("t_souffle"),
      //         category: 'Souffle',
      //       },
      //     ],
      //   },
      // ],

      item: '',
      profile: {
        id: '',
        internal_name: 'new_record',
        display_name: '',
        privileges: [],
      },

    }
  },
  components: {
    BAvatar,
    BFormCheckbox,
    BModal,
    vSelect,
    BFormInput,
    BCardHeader,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,

  },
  computed: {
    filteredUsers() {
      return this.object_list.temp_users.filter(v => v.identifier.includes(this.search_value));
    },
    filteredQueues() {
      return this.object_list.temp_queues.filter(v => v.display_name.includes(this.search_value));
    },
    filteredProjects() {
      return this.object_list.temp_projects.filter(v => v.display_name.includes(this.search_value));
    },
  },
  methods: {
    filterPrivileges(item) {
      this.tmp_privileges[item] = [...this.tmp_privileges2[item].filter(e => e.display_name.toLowerCase().includes(this.filterText[item]))];
      if (this.filterText[item].trim() == '') {
        this.tmp_privileges[item] = [...this.tmp_privileges2[item]];
      }
    },
    add_to_selected(key, value) {
      if (!this.selected_privileges[key].includes(value)) {
        this.selected_privileges[key].push(value);
      }
      else {
        var index = this.selected_privileges[key].indexOf(value);
        if (index !== -1) {
          this.selected_privileges[key].splice(index, 1);
        }
      }
      console.log("selected_privileges", this.selected_privileges);
    },
    deleteObject(obj) {
      this.deleted_object = obj;
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.DeleteProfileAssignment();

        }
      })
    },
    checkAll(check) {
      if (this.type_selection == 1) {
        for (const item of this.object_list.temp_users) {
          check ? item.checked = true : item.checked = false;
        }

        this.object_list.temp_users = [...this.object_list.temp_users];

      }
      if (this.type_selection == 2) {
        for (const item of this.object_list.temp_queues) {
          check ? item.checked = true : item.checked = false;
        }

        this.object_list.temp_queues = [...this.object_list.temp_queues];
      }
      if (this.type_selection == 3) {
        for (const item of this.object_list.temp_projects) {
          check ? item.checked = true : item.checked = false;
        }

        this.object_list.temp_projects = [...this.object_list.temp_projects];
      }
    },

    checkAllPrivileges(check, key) {
      if (check) {
        for (const row of this.tmp_privileges[key]) {
          row.checked = true;
          if (!this.selected_privileges[key].includes(row.internal_name)) {
            this.selected_privileges[key].push(row.internal_name);
          }
        }
        for (const row of this.tmp_privileges2[key]) {
          row.checked = true;
          if (!this.selected_privileges[key].includes(row.internal_name)) {
            this.selected_privileges[key].push(row.internal_name);
          }
        }
      } else {
        for (const row of this.tmp_privileges[key]) {
          row.checked = false;
        }
        for (const row of this.tmp_privileges2[key]) {
          row.checked = false;
        }
        this.selected_privileges[key] = [];
      }
      this.tmp_privileges[key] = [...this.tmp_privileges[key]];
      this.tmp_privileges2[key] = [...this.tmp_privileges2[key]];
    },
    DeleteProfilePopup: function () {
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.DeleteProfile();

        }
      })
    },
    DeleteProfile: async function () {
      this.is_progress = true;
      this.modal = false;
      var response = (
        await this.$http_in.delete(`Auth/v1/Profile/${this.profile.internal_name}`, {
          headers: {
            authorization: globalThis.qm_token,
          },
        })
      ).data;
      this.is_progress = false;
      if (response != null || response != undefined) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: `${this.profile.display_name} ${globalThis._lang("t_profileHasBeenDeletedSuccessfully")}`,
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        this.GetProfiles();
        this.create_new_profile();
      }
    },
    DeleteProfileAssignment: async function () {
      this.modal_delete_object = false;
      var response = (
        await this.$http_in.delete(`Auth/v1/ProfileAssignment/${this.deleted_object}/${this.profile.internal_name}`, {
          headers: {
            authorization: globalThis.qm_token
          },
        })
      ).data;
      await this.GetProfileObjects();
      await this.GetUsers();
      await this.GetQueues();
      await this.GetProjects();
      this.updateSelected(this.profile);
      this.$swal({
        icon: 'success',
        title: this.lang('t_processSuccess'),
        text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
        confirmButtonText: this.lang('t_ok'),
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });


    },
    test() {
      this.modal_object_list = true;
      console.log('this.selected_objects.users', this.selected_objects.users);
    },
    create_new_profile() {
      this.edit = false;
      this.profile = {
        id: '',
        internal_name: 'new_record',
        display_name: '',
        privileges: [],
      };

      this.profile_objects.users = [];
      this.profile_objects.queues = [];
      this.profile_objects.projects = [];
      console.log('test', this.temp_object_list);
      this.object_list.temp_users = this.object_list.users;
      this.object_list.temp_queues = this.object_list.queues;
      this.object_list.temp_projects = this.object_list.projects;
      // this.object_list.temp_users = this.object_list.temp_users.map(e => e.checked = false);
      // this.object_list.temp_queues = this.object_list.temp_queues.map(e => e.checked = false);
      // this.object_list.temp_projects = this.object_list.temp_projects.map(e => e.checked = false);
      this.selected_privileges = {
        status: [],
        finish_code: [],
        custom_tab: [],
        queue: [],
        dial_plan: [],
        menu: [],
        chat: [],
        crm: [],
        other: [],
        report: [],
      };

      // for (const item of Object.keys(this.tmp_privileges)) {
      //   for (const item2 of this.tmp_privileges[item]) {
      //     item2.checked = false;
      //   }
      // }

      // for (const item of Object.keys(this.tmp_privileges2)) {
      //   for (const item2 of this.tmp_privileges2[item]) {
      //     item2.checked = false;

      //   }
      // }

    },

    SetProfile: async function () {
      if (this.profile.display_name == '') {

        this.$swal({
          title: 'Warning!',
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
        return;
      }
      console.log("aaa", this.object_list.temp_users.filter(e => e.checked == true));
      this.selected_objects.users = this.object_list.temp_users.filter(e => e.checked == true);
      this.selected_objects.queues = this.object_list.temp_queues.filter(e => e.checked == true);
      this.selected_objects.projects = this.object_list.temp_projects.filter(e => e.checked == true);
      // return;
      this.profile.privileges = this.selected_privileges;
      this.is_progress = true;
      var response;
      if (!this.edit) {
        response = (
          await this.$http_in.post(`Auth/v1/Profile`,
            {
              data: JSON.stringify(this.profile),
              profile_assigments: JSON.stringify(this.selected_objects),
            },
            {
              headers: {
                authorization: globalThis.qm_token,
              },
            }
          )
        ).data;
      } else {
        response = (
          await this.$http_in.put(`Auth/v1/Profile`,
            {
              data: JSON.stringify(this.profile),
              profile_assigments: JSON.stringify(this.selected_objects),
            },
            {
              headers: {
                authorization: globalThis.qm_token,
              },
            }
          )
        ).data;
      }

      this.selected_objects.users = [];
      this.selected_objects.queues = [];
      this.selected_objects.projects = [];
      Promise.all([
        this.GetUsers(),
        this.GetQueues(),
        this.GetProjects(),
        this.GetProfileObjects(),
        this.GetProfiles(),
      ]);

      this.updateSelected(this.profile);
      this.is_progress = false;

      console.log(response.command);
      if (response != null || response != undefined) {
        if (response.command == 'UPDATE') {
          this.$swal({
            icon: 'success',
            title: this.lang('t_processSuccess'),
            text: `${this.profile.display_name} ${globalThis._lang("t_profileHasBeenUpdatedSuccessfully")}`,
            confirmButtonText: this.lang('t_ok'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        } else {
          this.$swal({
            icon: 'success',
            title: this.lang('t_processSuccess'),
            text: globalThis._lang("t_profileHasBeenCreatedSuccessfully"),
            confirmButtonText: this.lang('t_ok'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });

          // this.create_new_profile();
          await this.GetProfiles(true);
        }
      }
    },

    GetProfiles: async function (update = false) {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(`Auth/v1/Profiles`)
      ).data;

      console.log(response);
      this.profiles = [{
        id: '',
        internal_name: 'new_record',
        display_name: '',
        privileges: [],
      }, ...response];
      this.is_progress = false;
      // this.profiles = response;
      // if (update) {
      //   this.updateSelected(response[response.length - 1]);
      // }
    },
    updateSelected: function (selectedItem) {
      console.log(selectedItem);
      this.profile = selectedItem;

      this.edit = true;
      this.is_alert = false;
      this.selected_privileges = {
        status: [],
        finish_code: [],
        custom_tab: [],
        queue: [],
        dial_plan: [],
        menu: [],
        chat: [],
        crm: [],
        other: [],
        report: [],
      };
      if (![undefined, null, []].includes(selectedItem.privileges)) {
        for (const item of Object.keys(this.tmp_privileges)) {
          for (const item2 of this.tmp_privileges[item]) {
            item2.checked = false;
            if (selectedItem.privileges[item] && selectedItem.privileges[item].includes(item2.internal_name)) {
              item2.checked = true;
              if (!this.selected_privileges[item].includes(item2.internal_name)) {
                this.selected_privileges[item].push(item2.internal_name);
              }
            }
          }
        }
      }
      if (![undefined, null, []].includes(selectedItem.privileges)) {
        for (const item of Object.keys(this.tmp_privileges2)) {
          for (const item2 of this.tmp_privileges2[item]) {
            item2.checked = false;
            if (selectedItem.privileges[item] && selectedItem.privileges[item].includes(item2.internal_name)) {
              item2.checked = true;
              if (!this.selected_privileges[item].includes(item2.internal_name)) {
                this.selected_privileges[item].push(item2.internal_name);
              }
            }
          }
        }
      }

      // for (const item of Object.keys(selectedItem.privileges)) {
      //   for (const item2 of this.tmp_privileges[item]) {
      //     item2.checked = false;
      //     if (selectedItem.privileges[item].includes(item2.internal_name)) {
      //       item2.checked = true;
      //       this.selected_privileges[item].push(item2.internal_name);
      //     }
      //   }
      // }

      console.log("this.tmp_privileges", this.tmp_privileges);
      this.profile.internal_name =
        this.profile.internal_name ||
        `${this.$store.state.agent.Domain}_profile_${this.profile.display_name.toLowerCase().split(' ').join('_')}`;
      console.log('this.profile.internal_name', this.profile);
      this.profile.internal_name = this.remove_turkish_characters(this.profile.internal_name);
      this.profile_objects.users = this.temp_profile_objects.users.filter(
        (e) => e.profile_name == this.profile.internal_name
      );
      this.profile_objects.queues = this.temp_profile_objects.queues.filter(
        (e) => e.profile_name == this.profile.internal_name
      );
      this.profile_objects.projects = this.temp_profile_objects.projects.filter(
        (e) => e.profile_name == this.profile.internal_name
      );

      this.object_list.temp_users = this.object_list.users.filter(
        (e) => !e.profiles.includes(this.profile.internal_name)
      );
      this.object_list.temp_queues = this.object_list.queues.filter(
        (e) => !e.profiles.includes(this.profile.internal_name)
      );
      this.object_list.temp_projects = this.object_list.projects.filter(
        (e) => !e.profiles.includes(this.profile.internal_name)
      );


    },
    remove_turkish_characters(text) {
      var charMap = {
        Ç: 'C',
        Ö: 'O',
        Ş: 'S',
        İ: 'I',
        I: 'i',
        Ü: 'U',
        Ğ: 'G',
        ç: 'c',
        ö: 'o',
        ş: 's',
        ı: 'i',
        ü: 'u',
        ğ: 'g',
      };

      var str = text;

      var str_array = str.split('');

      for (var i = 0, len = str_array.length; i < len; i++) {
        str_array[i] = charMap[str_array[i]] || str_array[i];
      }

      str = str_array.join('');

      return str.replace(/[çöşüğı]/gi, '');
    },

    // ----------- NEW --------

    GetStatusAndCategories: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/Status`)
      ).data;

      var _status = response.status
        .filter((s) => !s.is_system)
        .map((e) => {
          return {
            internal_name: e._id,
            display_name: e.display_name,
          };
        });

      console.log("aaaadd", _status);
      this.tmp_privileges.status = _status;
      this.tmp_privileges2.status = _status;
      //this.status = response.status;
    },
    GetFinishCodes: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/FinishCodes`)
      ).data;

      var _finish_codes = response.finish_codes.filter(e => !e.is_system).map((e) => {
        return {
          internal_name: e.finish_code,
          display_name: e.finish_code,
        };
      });
      this.tmp_privileges.finish_code = _finish_codes;
      this.tmp_privileges2.finish_code = _finish_codes;

      // this.privileges[0].children[1].children = _finish_codes;
    },

    GetProjects: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/Project`)
      ).data;

      this.object_list.projects = response;
      this.object_list.temp_projects = response;

      for (const item of response) {
        this.temp_object_list.projects.push({
          internal_name: item.internal_name,
          display_name: item.display_name,
        });
        this.project_names.set(item.internal_name, item.display_name);
      }
    },

    GetUsers: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/User`)
      ).data;

      this.object_list.users = response;
      this.object_list.temp_users = response;
      for (const iterator of response) {
        console.log('iterator.username', iterator.username);
        this.temp_object_list.users.push({ username: iterator.identifier });
      }
    },

    GetTabs: async function () {
      var response = (
        await this.$http.get(`/CustomTabMgmt`, {
          headers: {
            authorization: globalThis.token,
          },
        })
      ).data;

      for (const item of response) {
        this.custom_tab_names.set(item.internal_name, item.display_name);
      }

      var _customtabs = response.map((e) => {
        return {
          internal_name: e.internal_name,
          display_name: e.display_name,
        };
      });
      this.tmp_privileges.custom_tab = _customtabs;
      this.tmp_privileges2.custom_tab = _customtabs;

      // this.privileges[0].children[2].children = _customtabs;
    },

    GetDialPlans: async function () {
      var response = (
        await this.$http_in.get(`/smgr/v1/Classification`)
      ).data;

      var _dial_plans = response.map((e) => {
        return {
          internal_name: e._id,
          display_name: `${e.queue == 'undefined' ? 'Tanımsız' : this.queue_names.get(e.queue)} - ${e.name}`,
        };
      });

      this.tmp_privileges.dial_plan = _dial_plans;
      this.tmp_privileges2.dial_plan = _dial_plans;

      // this.privileges[0].children[4].children = _dial_plans;
    },

    GetRoutes: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/PermissionName`)
      ).data;

      var _routes = response.filter(e => e.key == 'menu' && !e.default).map((e) => {
        return {
          internal_name: e.value,
          display_name: globalThis._lang(e.display_name),
        };
      });
      this.tmp_privileges.menu = _routes;
      this.tmp_privileges2.menu = _routes;

      var _chat = response.filter(e => e.key == 'chat').map((e) => {
        return {
          internal_name: e.value,
          display_name: globalThis._lang(e.display_name),
        };
      });
      this.tmp_privileges.chat = _chat;
      this.tmp_privileges2.chat = _chat;

      var _crm = response.filter(e => e.key == 'crm').map((e) => {
        return {
          internal_name: e.value,
          display_name: globalThis._lang(e.display_name),
        };
      });
      this.tmp_privileges.crm = _crm;
      this.tmp_privileges2.crm = _crm;

      var _other = response.filter(e => e.key == 'other').map((e) => {
        return {
          internal_name: e.value,
          display_name: globalThis._lang(e.display_name),
        };
      });
      this.tmp_privileges.other = _other;
      this.tmp_privileges2.other = _other;

      // this.privileges[0].children[5].children = _routes;
    },

    GetQueues: async function () {
      var response = (
        await this.$http_in.get(`optmgr/v1/Queue`)
      ).data;

      this.object_list.queues = response;
      this.object_list.temp_queues = response;

      for (const item of response) {
        this.temp_object_list.queues.push({
          internal_name: item.internal_name,
          display_name: item.display_name,
        });
        this.queue_names.set(item.internal_name, item.display_name);
      }

      var _queues = response.map((e) => {
        return {
          internal_name: e.internal_name,
          display_name: e.display_name,
        };
      });
      this.tmp_privileges.queue = _queues;
      this.tmp_privileges2.queue = _queues;

      // this.privileges[0].children[3].children = _queues;
      console.log(response);
    },
    GetReports: async function () {
      var response = (
        await this.$http_in.get(`report/v1/Reports`)
      ).data;

      var _reports = response.map((e) => {
        return {
          internal_name: e.internal_name,
          display_name: globalThis._lang(e.display_name),
        };
      });
      this.tmp_privileges.report = _reports;
      this.tmp_privileges2.report = _reports;

      // this.privileges[0].children[3].children = _queues;
      console.log(response);
    },
    GetProfileObjects: async function () {
      var response = (
        await this.$http_in.get(`Auth/v1/ProfileObjects`, {
          headers: {
            authorization: globalThis.qm_token,
          },
        })
      ).data;

      this.temp_profile_objects.users = [];
      this.temp_profile_objects.queues = [];
      this.temp_profile_objects.projects = [];

      for (const item of response) {
        if (item.type == 'user') {
          this.temp_profile_objects.users.push(item);
        } else if (item.type == 'queue') {
          this.temp_profile_objects.queues.push(item);
        } else {
          this.temp_profile_objects.projects.push(item);
        }
      }
    },
    flip_bool: function (bool_name) {
      this[bool_name] = !this[bool_name];
    },
    create_UUID() {
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });
      return uuid;
    },
  },
  mounted: async function () {
    this.is_progress = true;
    // Promise.all(
    //   [
    //     this.GetQueues(),
    //     this.GetProjects(),
    //     this.GetUsers(),
    //     this.GetProfileObjects(),
    //     this.GetProfiles(),
    //     this.GetStatusAndCategories(),
    //     this.GetFinishCodes(),
    //     this.GetDialPlans(),
    //     this.GetRoutes(),
    //     this.GetTabs(),
    //     this.GetReports(),
    //     this.create_new_profile(),
    //   ]

    // );
    await this.GetQueues(),
      await this.GetProjects(),
      await this.GetUsers(),
      this.GetProfileObjects(),
      this.GetProfiles(),
      this.GetStatusAndCategories(),
      this.GetFinishCodes(),
      this.GetDialPlans(),
      this.GetRoutes(),
      this.GetTabs(),
      this.GetReports(),
      this.create_new_profile(),
      this.is_progress = false;












  },

}
</script>

<style lang="scss">
// @import "@core/scss/vue/libs/vue-select.scss";
</style>
