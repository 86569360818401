<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button
                variant="primary"
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="finish_codes.filter((e) => !e.is_system)"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
        :filter="searchQuery"
      >
        <template #cell(category_id)="data">
          {{
            finish_categories.find((e) => e._id == data.item.category_id)
              .category
          }}
        </template>
        <template #cell(require_date)="data">
          <feather-icon
            :icon="data.item.require_date ? 'CheckIcon' : 'XIcon'"
            size="12"
            class="align-middle text-body"
          />
        </template>
        <template #cell(save_calendar)="data">
          <feather-icon
            :icon="data.item.save_calendar ? 'CheckIcon' : 'XIcon'"
            size="12"
            class="align-middle text-body"
          />
        </template>
        <template #cell(is_last_finish_code)="data">
          <feather-icon
            :icon="data.item.is_last_finish_code ? 'CheckIcon' : 'XIcon'"
            size="12"
            class="align-middle text-body"
          />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            :disabled="data.item.is_system ? true : false"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ finish_codes.length }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- finishCodeCategory -->
          <validation-provider
            #default="validationContext"
            name="finishCodeCategory"
            rules="required"
          >
            <b-form-group
              :label="lang('t_finishCodeCategory')"
              label-for="finishCodeCategory"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="finishCodeCategory"
                v-model="currentData.category_id"
                :options="finish_categories"
                :placeholder="lang('t_finishCodeCategory')"
                :reduce="(val) => val._id"
                label="category"
              ></v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- finishCodeName -->
          <validation-provider
            #default="validationContext"
            name="finishCodeName"
            rules="required"
          >
            <b-form-group
              :label="lang('t_finishCodeName')"
              label-for="finishCodeName"
            >
              <b-form-input
                id="finishCodeName"
                v-model="currentData.finish_code"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-row class="mt-1">
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.require_date"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_askForActionDate") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                :disabled="!currentData.require_date"
                v-model="currentData.save_calendar"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_saveToCalendar") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.is_last_finish_code"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_lastFinishCode") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.is_contact_callable"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_contactCallableAfterwards") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.is_phone_callable"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_contactNumberCallableAfterwards") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.is_phone_attempts"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_countAsCallAttempt") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.is_pnd"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_deleteContactNumber") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                @input="checkAssign(true)"
                v-model="currentData.is_assign"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_assignToAgent") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.is_extra_time"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_giveExtraTime") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.show_in_dashboard"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_showInDashboard") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                @input="set_finish_code_type('product')"
                v-model="currentData.is_product_finish_code"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_isProductFinishCode") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                @input="set_finish_code_type('chat')"
                v-model="currentData.is_chat_finish_code"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_isChatFinishCode") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.is_auto_sms"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_isAutoSMS") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                @input="checkAssign(false)"
                v-model="currentData.is_unassign"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_isUnassign") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.is_passive"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_isPassive") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.is_auto_email"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_isAutoEmail") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.is_reached"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_isReached") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="currentData.is_auto_sms">
            <b-col>
              <b-form-group
                :label="lang('t_smsTemplate')"
                label-for="t_smsTemplate"
              >
                <v-select
                  v-model="currentData.sms_template"
                  :options="sms_templates"
                  label="display_name"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="lang('t_operator')" label-for="t_operator">
                <v-select
                  v-model="currentData.sms_settings"
                  :options="sms_settings"
                  label="display_name"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="lang('t_smsDelayAmount')"
                label-for="t_smsDelayAmount"
              >
                <b-form-input
                  v-model="currentData.sms_delay_amount"
                  trim
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="currentData.is_auto_email">
            <b-col>
              <b-form-group
                :label="lang('t_emailTemplate')"
                label-for="t_emailTemplate"
              >
                <v-select
                  v-model="currentData.email_template"
                  :options="email_templates"
                  label="display_name"
                >
                </v-select>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group :label="lang('t_account')" label-for="t_account">
                <v-select
                  v-model="currentData.email_settings"
                  :options="email_settings"
                  label="display_name"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="lang('t_emailDelayAmount')"
                label-for="t_emailDelayAmount"
              >
                <b-form-input
                  v-model="currentData.email_delay_amount"
                  trim
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="currentData.is_extra_time">
            <b-col>
              <b-form-group
                :label="lang('t_time')"
                label-for="extra_time_amount"
              >
                <b-form-input
                  v-model="currentData.extra_time_amount"
                  trim
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="lang('t_status')"
                label-for="extra_time_amount"
              >
                <v-select
                  v-model="currentData.extra_time_status"
                  :options="statuses.filter((e) => e.is_system != 1)"
                  label="display_name"
                  :reduce="(val) => val._id"
                  value="_id"
                >
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
             {{lang('t_add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
             {{lang('t_cancel')}}
            </b-button>
          </div>
          -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // UsersListFilters,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      finish_categories: [],
      statuses: [],
      finish_codes: [],
      sms_templates: [],
      sms_settings: [],
      selected_code: {},
      selected_category: {},

      currentData: {
        finish_code: "",
        category_id: "",
        require_date: false,
        require_note: false,
        save_calendar: false,
        is_phone_attempts: true,
        is_contact_callable: true,
        is_phone_callable: true,
        is_pnd: false,
        is_assign: false,
        is_extra_time: false,
        extra_time_amount: "0",
        extra_time_status: "",
        is_last_finish_code: false,
        show_in_dashboard: false,
        is_product_finish_code: false,
        is_chat_finish_code: false,
        is_reached: true,
        is_auto_sms: false,
        sms_template: {},
        sms_settings: {},
        sms_delay_amount: 0,
        is_auto_email: false,
        email_template: {},
        email_settings: {},
        email_delay_amount: 0,
        is_unassign: false,
        is_passive: false,
      },
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      tableColumns: [
        {
          key: "category_id",
          label: globalThis._lang("t_finishCodeCategory"),
          sortable: true,
        },
        {
          key: "finish_code",
          label: globalThis._lang("t_finishCodeName"),
          sortable: true,
        },
        {
          key: "require_date",
          label: globalThis._lang("t_askForActionDate"),
          sortable: true,
        },
        {
          key: "save_calendar",
          label: globalThis._lang("t_saveToCalendar"),
          sortable: true,
        },
        {
          key: "is_last_finish_code",
          label: globalThis._lang("t_lastFinishCode"),
          sortable: true,
        },
        {
          key: "actions",
          label: globalThis._lang("t_actions"),
          sortable: false,
        },
      ],
    };
  },
  mounted: function () {
    this.get_records();
    this.get_statuses();
    this.GetSmsTemplate();
    this.GetSmsSettings();
    this.GetEMailTemplate();
    this.GetEMailSettings();
  },

  methods: {
    checkAssign(type) {
      console.log(type);
      if (type) {
        if (this.currentData.is_assign) {
          this.currentData.is_unassign = false;
        }
      } else {
        if (this.currentData.is_unassign) {
          this.currentData.is_assign = false;
        }
      }
    },
    set_finish_code_type(type) {
      if (type == 'chat' && this.currentData.is_chat_finish_code) {
        this.currentData.is_product_finish_code = false;
      }
      if (type == 'product' && this.currentData.is_product_finish_code) {
        this.currentData.is_chat_finish_code = false;
      }
    },
    GetEMailTemplate: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/EMailTemplate`)).data;
      this.email_templates = response;
    },
    GetEMailSettings: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/EMailSettings`)).data;
      this.email_settings = response;
    },
    GetSmsTemplate: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/SmsTemplate`)).data;
      this.sms_templates = response;
    },
    GetSmsSettings: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/SmsSettings`)).data;
      this.sms_settings = response;
    },

    get_statuses: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/Status`)).data;
      console.log("statuses", response);
      this.statuses = response.status;
    },
    get_records: async function () {
      this.is_progress = true;

      var response = (await this.$http_in.get(`/optmgr/v1/FinishCodes`)).data;
      console.log("FinishCodes", response);
      this.finish_codes = response.finish_codes;
      this.finish_categories = response.finish_categories;

      this.is_progress = false;
    },
    check_data() {
      if (
        this.currentData.finish_code == "" ||
        this.currentData.category_id == ""
      ) {
        return true;
      }
      return false;
    },
    add_records: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.is_progress = true;
      var response;
      if (!this.isEdit) {
        if (
          this.finish_codes.find(
            (e) => e.finish_code == this.currentData.finish_code
          ) != undefined
        ) {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          return;
        }

        response = (
          await this.$http_in.post(`/optmgr/v1/FinishCode`, {
            FinishCodeInfo: this.currentData,
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`/optmgr/v1/FinishCode`, {
            FinishCodeInfo: this.currentData,
          })
        ).data;
      }
      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.delete(
          `/optmgr/v1/FinishCode/${this.currentData._id}`,
          {
            headers: {
              authorization: globalThis.token,
            },
          }
        )
      ).data;
      this.is_progress = false;

      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.code_edit = false;
      this.selected_code = {};
      this.currentData = {
        _id: "",
        finish_code: "",
        category_id: "",
        require_date: false,
        require_note: false,
        save_calendar: false,
        is_phone_attempts: true,
        is_contact_callable: true,
        is_phone_callable: true,
        is_pnd: false,
        is_assign: false,
        is_extra_time: false,
        extra_time_amount: "0",
        extra_time_status: "",
        is_last_finish_code: false,
        show_in_dashboard: false,
        is_product_finish_code: false,
        is_chat_finish_code: false,
        is_reached: true,
        is_auto_sms: false,
        sms_template: {},
        sms_settings: {},
        sms_delay_amount: 0,
        is_auto_email: false,
        email_template: {},
        email_settings: {},
        email_delay_amount: 0,
        is_unassign: false,
        is_passive: false,
      };
    },

    updateSelected(item) {
      this.selected_code = item;
      this.currentData = {
        ...item,
      };
      this.isEdit = true;
      this.modal_record_details = true;
      //this.isAddNewUserSidebarActive = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const categoryOptions = [
      { label: "Kat1", value: "1" },
      { label: "Kat2", value: "2" },
      { label: "Kat3", value: "3" },
      { label: "Kat4", value: "4" },
    ];
    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      categoryOptions,
      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
