<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-card>
      <b-card-text>
        <b-form-group>
          <v-select
            @input="updateSelected"
            v-model="queue_group"
            :options="queue_groups"
            :placeholder="lang('t_lineGroup')"
            :reduce="(option) => option"
            label="display_name"
            value="internal_name"
          >
            <template v-slot:option="option">
              <!-- <span :class="'UserIcon'"></span> -->
              <span v-if="option.internal_name == 'new_record'">
                <feather-icon icon="PlusIcon" />
                {{ lang("t_addNewRecord") }}
              </span>
              <span v-else>{{ option.display_name }}</span>
            </template>
          </v-select>
        </b-form-group>

        <b-form-input
          v-model="queue_group.display_name"
          :placeholder="lang('t_displayName')"
          id="linegroup"
          trim
          class="mt-50"
        />

        <b-button
          @click="set_queue_group"
          class="float-right ml-1 mt-1"
          variant="primary"
          >{{ lang("t_save") }}</b-button
        >

        <b-button
          @click="clearAll"
          v-if="queue_group.internal_name != 'new_record'"
          class="float-right mt-1"
          variant="danger"
          >{{ lang("t_delete") }}</b-button
        >
      </b-card-text>
    </b-card>
    <b-card style="height: 53vh">
      <b-row align-v="center">
        <b-col cols="5">
          <b-card
            border-variant="primary"
            :title="lang('t_selectableQueues')"
            class="business-card"
            style="min-height: 45vh; max-height: 45vh; overflow-y: auto"
          >
            <div class="business-items">
              <div
                v-for="(lineGroupItem, i) in tmp_potential_queues"
                :key="i"
                class="business-item"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <b-form-checkbox v-model="lineGroupItem.checked">
                    {{ lineGroupItem.display_name }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col cols="2" style="min-height: 200px">
          <b-row>
            <b-col cols="12" class="text-center mt-1">
              <b-button
                @click="add_line_to_group"
                variant="primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="ArrowRightIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-row class="text-center mt-1">
            <b-col cols="12">
              <b-button
                @click="remove_line_from_group"
                variant="primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="5">
          <b-card
            border-variant="primary"
            :title="lang('t_selectedQueues')"
            class="business-card"
            style="min-height: 45vh; max-height: 45vh; overflow-y: auto"
          >
            <div class="business-items">
              <div
                v-for="(lineGroupItem2, i) in tmp_unpotential_queues"
                :key="i"
                class="business-item"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <b-form-checkbox v-model="lineGroupItem2.checked">
                    {{ lineGroupItem2.display_name }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";

import {
  VBModal,
  BModal,
  BButton,
  BRow,
  BCol,
  BBadge,
  BFormInput,
  BFormGroup,
  BCard,
  BCardText,
  BLink,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      selectable_queues: [],
      tmp_potential_queues: [],
      unselectable_queues: [],
      tmp_unpotential_queues: [],
      queue_groups: [],
      queue_list: [],
      queue_group: {
        internal_name: "new_record",
        display_name: "",
        queues: [],
      },
      selected_item: {},
    };
  },
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
    BBadge,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
  },
  directives: {
    "b-modal": VBModal,
  },
  mounted: function () {
    this.get_queue();
    this.get_queue_groups();
    this.create_new();
  },
  methods: {
    add_line_to_group() {
      this.selectable_queues = this.tmp_potential_queues.filter(
        (e) => e.checked == true
      );
      console.log(this.selectable_queues);

      this.selectable_queues.map((e) => {
        this.tmp_unpotential_queues.push(e);
        this.removeA(this.tmp_potential_queues, e);
      });

      this.tmp_potential_queues = this.tmp_potential_queues.map((obj) => ({
        ...obj,
        checked: false,
      }));
      this.tmp_unpotential_queues = this.tmp_unpotential_queues.map((obj) => ({
        ...obj,
        checked: false,
      }));

      // this.selectable_queues = [];
      // this.unselectable_queues = [];
    },
    remove_line_from_group() {
      this.unselectable_queues = this.tmp_unpotential_queues.filter(
        (e) => e.checked == true
      );

      this.unselectable_queues.map((e) => {
        this.tmp_potential_queues.push(e);
        this.removeA(this.tmp_unpotential_queues, e);
      });
      this.tmp_potential_queues = this.tmp_potential_queues.map((obj) => ({
        ...obj,
        checked: false,
      }));
      this.tmp_unpotential_queues = this.tmp_unpotential_queues.map((obj) => ({
        ...obj,
        checked: false,
      }));
    },
    removeA(arr) {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
    },

    get_queue_groups: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/optmgr/v1/QueueGroup`)).data;

      this.queue_groups = [
        {
          internal_name: "new_record",
          display_name: "",
          lines: [],
        },
        ...response,
      ];
      this.is_progress = false;
      console.log(" this.queue_groups", this.queue_groups);
    },
    get_queue: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/Queue`)).data;

      response = response.map((obj) => ({ ...obj, checked: false }));

      this.tmp_potential_queues = [...response];
      this.queue_list = [...response];
    },
    editItem(item) {
      console.log("this is from parent:", item);
      this.selected_item = item;
      console.log("this.selected_item", this.selected_item);

      this.edit_modal = true;
      console.log(" this.modal", this.edit_modal);
    },
    delete_queue_group: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.delete(
          `/optmgr/v1/QueueGroup/${this.queue_group._id}`
        )
      ).data;
      this.is_progress = false;
      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        this.create_new();
        this.get_queue_groups();
      }
    },
    clearAll() {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_queue_group();
        }
      });
      // console.log("line_group", this.line_group);
      // var _list = this.line_group.linegroup_object.slice();
      // this.list = _list;
    },
    set_queue_group: async function () {
      if (
        this.queue_group.display_name == "" ||
        this.queue_group.display_name == null
      ) {
        this.$swal({
          icon: "warning",
          title: "",
          text: globalThis._lang("t_pleaseEnterTheLineGroupName"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      if (this.tmp_unpotential_queues.length == 0) {
        this.$swal({
          icon: "warning",
          title: "",
          text: globalThis._lang(
            "t_youNeedToPickALineToBeAbleToCreateLineGroup"
          ),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }

      this.is_progress = true;
      this.queue_group.queues = this.tmp_unpotential_queues;

      var response;
      if (!this.edit) {
        response = (
          await this.$http_in.post(`/optmgr/v1/QueueGroup`, {
            data: JSON.stringify(this.queue_group),
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`/optmgr/v1/QueueGroup`, {
            data: JSON.stringify(this.queue_group),
          })
        ).data;
      }

      this.is_progress = false;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.create_new();
      this.get_queue_groups();
    },
    updateSelected(selectedItem) {
      console.log("updateSelected", selectedItem);

      if (selectedItem.internal_name == "new_record") {
        this.tmp_potential_queues = [...this.queue_list];
        this.tmp_unpotential_queues = [];
        this.selectable_queues = [];
        this.unselectable_queues = [];
        this.edit = false;
        for (const item of this.tmp_potential_queues) {
          item.checked = false;
        }
        for (const item of this.tmp_unpotential_queues) {
          item.checked = false;
        }
        return;
      }
      this.edit = true;
      this.tmp_potential_queues = [...this.queue_list];
      this.tmp_unpotential_queues = [];
      this.selectable_queues = [];
      this.unselectable_queues = [];
      var _queues = selectedItem.queues;
      for (const item of this.tmp_potential_queues) {
        item.checked = false;
      }
      for (const item of this.tmp_unpotential_queues) {
        item.checked = false;
      }

      for (const row of _queues) {
        var _row = this.tmp_potential_queues.find(
          (e) => e.internal_name == row.internal_name
        );
        if (_row != undefined) {
          _row.checked = true;
        }
      }
      this.add_line_to_group();
    },
    create_new() {
      this.edit = false;
      this.queue_group = {
        internal_name: "new_record",
        display_name: "",
        queues: [],
      };

      this.tmp_potential_queues = [...this.queue_list];
      this.tmp_unpotential_queues = [];
      this.selectable_queues = [];
      this.unselectable_queues = [];
      for (const item of this.tmp_potential_queues) {
        item.checked = false;
      }
      for (const item of this.tmp_unpotential_queues) {
        item.checked = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
